






































































































































































































/* .el-card__body {
      margin: 10px !important;
  } */

.course_card_data {
  min-height: 500px;
  .img_wrapper {
    .image {
      height: 250px;
      padding: 5px;
      object-fit: contain;
    }
  }
  .content_wrapper {
    padding: 10px;
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.course_container {
  .images {
    .course_img {
      max-width: 400px;
      max-height: 400px;
      margin: 0 auto 20px;
      display: block;
      padding: 10px;
      border-radius: 10px;
    }
  }
  .course_text {
    font-size: 18px;
    line-height: 1.7;
    word-break: break-word;
    padding: 10px;
    text-align: center;
  }
  .close_footer {
    margin-top: 20px;
    .close_close {
      padding: 8px 26px;
      border-radius: 5px;
      border: none;
      background-color: #f56c6c;
      color: #fff;
    }
  }
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .show_icons {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    color: #777;
    &:hover {
      background-color: #23ccef;
      color: #fff;
    }
  }
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  height: 250px;
  margin: auto;
}

span {
  font-size: 1.2rem;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

/* delete form style  */

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}
